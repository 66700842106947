* {
  padding: 0;
  margin: 0;
}
body {
  font-family: 'Poppins', sans-serif !important;
}
ul,
li,
ol {
  list-style: none;
}
body {
  background: #ffffff !important;
}
.bg-black {
  background-color: #000000;
}
.hero-row {
  text-align: center;
}
.hero-section .hero-row {
  display: grid;
  column-gap: 30px;
  grid-template-columns: 1fr;
  align-items: center;
}
@media only screen and (min-width: 280px) {
  .innerDiv {
    padding-left: 30px;
    padding-right: 30px;
  }
  .hero-section {
    padding: 30px 0;
  }
  .hero-row h1 {
    font-size: 30px;
  }
}

/* Tablet (sm)  */
@media only screen and (min-width: 640px) {
  .innerDiv {
    padding-left: 60px;
    padding-right: 60px;
  }
  .hero-section {
    padding: 50px 0;
  }
  .hero-row h1 {
    font-size: 40px;
  }
  .hero-row {
    text-align: left;
  }
  .link-dropdown {
    top: 100%;
  }
}

/* Tablet (md)  */
@media only screen and (min-width: 768px) {
  .innerDiv {
    padding-left: 60px;
    padding-right: 60px;
  }
  .hero-section {
    padding: 60px 0;
  }
  .hero-row h1 {
    font-size: 45px;
  }
}

/* Desktop (lg) */
@media only screen and (min-width: 1024px) {
  .innerDiv {
    padding-left: 80px;
    padding-right: 80px;
  }
  .hero-section {
    padding: 80px 0;
  }
  .hero-row h1 {
    font-size: 54px;
  }
  .hero-section .hero-row {
    grid-template-columns: 1fr 1fr;
  }
}

/* Desktop (xl)  */
@media only screen and (min-width: 1280px) {
  .innerDiv {
    max-width: calc(1280px + 160px);
    margin: 0 auto;
    padding-left: 80px;
    padding-right: 80px;
  }
  .hero-section {
    padding: 100px 0;
  }
  .hero-row h1 {
    font-size: 58px;
  }
}

/* Desktop (2xl)  */
@media only screen and (min-width: 1536px) {
  .innerDiv {
    max-width: 1280px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  .hero-section {
    padding: 120px 0;
  }
  .hero-row h1 {
    font-size: 58px;
  }
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.inner-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inner-header > a,
.dropdown {
  padding: 10px 0;
}
header .user-profile {
  border-radius: 50%;
}

.text-primary {
  color: #f9c933;
}
.hero-row img {
  width: 90%;
}
.hero-row h1 {
  font-weight: bold;
}
.hero-row p {
  color: #313131;
  font-size: 18px;
  line-height: 35px;
  margin-bottom: 35px;
}
.hero-btn {
  display: inline-block;
  padding: 15px 20px;
  color: #000000;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.hero-btn.bg-primary {
  box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.bg-primary {
  background-color: #f9c933;
}
.bg-white {
  background-color: #ffffff;
}
.mt-15p {
  margin-top: 15px;
}
.text-left {
  text-align: left;
}
.relative {
  position: relative;
}
.link-dropdown {
  border: 1px solid #dedede;
  border-radius: 20px;
  padding: 0 20px;
  position: absolute;
  left: 0;
  background-color: #ffffff;
  margin-top: 10px;
}
.link-dropdown > li {
  padding: 10px 5px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
}
.hero-row h1 {
  margin-bottom: 20px;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  margin: 0 auto;
}
.modal-dialog {
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(100px);
  border-radius: 20px;
  padding: 10px;
}
.modal .modal-dialog .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  margin-bottom: 15px;
}
.modal .modal-dialog .modal-header h3 {
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}
.modal .modal-dialog .modal-header span {
  color: white;
  font-size: 18px;
}
.modal .modal-dialog .modal-body {
  padding: 5px 10px;
}
.modal .modal-dialog .modal-body p {
  color: #ffffff;
  margin-bottom: 10px;
}
.modal .modal-dialog .modal-body .input-group {
  display: flex;
  align-items: center;
}
.modal .modal-dialog .modal-body .input-group input {
  background: rgba(38, 38, 38, 0.3);
  border-radius: 10px;
  border: none;
  width: 90%;
  padding: 10px 15px;
  font-size: 12px;
}
.modal .modal-dialog .modal-body .input-group input::placeholder {
  color: #ffffff;
}
.modal .modal-dialog .modal-body .input-group i,
.modal .modal-dialog .modal-body .input-group span {
  color: #ffffff;
  margin-left: 5px;
}
.cursor-pointer {
  cursor: pointer;
}
.text-white {
  color: white;
}
.primary-btn,
.primary-btn:hover,
.primary-btn:focus {
  background: #f9c933 !important;
}
.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8 {
  background: rgba(32, 32, 32, 0.2);
  backdrop-filter: blur(60px);
  border-radius: 10px;
  box-shadow: none;
}
.iconBottom {
  color: #ffffff;
  font-size: 24px !important;
}
@media (max-width: 767px) {
  .bottomIcon {
    width: 16px;
    height: 16px;
  }
  .hero-row h1 {
    margin-bottom: 20px;
  }
  .hero-row p {
    font-size: 16px;
  }
  .hero-row img {
    width: 100%;
  }
}

.hideFirst {
  display: none;
}
.login-btn {
  display: inline-block;
  padding: 10px 30px;
  background: #f9c933;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 600;
}
.bg-none {
  background: transparent;
}
.button-main button.MuiButton-root:hover,
.button-main button.MuiButton-root:focus,
.button-main button.MuiButton-root:active {
  background: #202020 !important;
}
.dropdown {
  position: relative;
  cursor: pointer;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  min-width: 10rem;
  padding: 0 1rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  right: 0;
  top: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.dropdown-menu li a {
  display: block;
  padding: 10px 5px;
  font-size: 16px;
  cursor: pointer;
  color: #000000;
  text-decoration: none;
}

.hero-section {
  background-color: #ffffff;
}
